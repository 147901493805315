.c-promo {
    text-align: center;
}

.c-promo__visual {
    position: relative;

    @include mq($from: tablet) {
        min-height: 468px;
    }
}

.c-promo__background {
    @include mq($until: tablet) {
        display: none;
    }

    @include mq($from: tablet) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        height: 404px;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.c-promo-media {
    @include mq($until: tablet) {
        height: 0;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
    }

    @include mq($from: tablet) {
        position: absolute;
        top: 64px;
        left: 50%;
        transform: translateX(-50%);
        width: 718px;
        height: 370px;
        background: url('/img/global/laptop-gfx.svg') no-repeat;
    }
}

.c-promo-media__embed {
    @include mq($until: tablet) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include mq($from: tablet) {
        position: absolute;
        top: 23px;
        left: 50%;
        transform: translateX(-50%);
        width: 540px;
        height: 304px;
    }

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    > iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.c-promo__text {
    font-size: 2rem;
    line-height: 1.3;
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
    color: var(--grey-darkest-color);

    @include mq($until: tablet) {
        margin-top: $spacing-unit-large;
    }

    @include mq($from: desktop) {
        font-size: 2.4rem;
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    b,
    strong {
        color: var(--base-color);
    }
}

.c-promo__action {
    margin-top: $spacing-unit-large;
}