.c-link {
    font-size: 1.8rem;
    line-height: 24px;
    display: inline-flex;
    color: var(--link-color);
    font-weight: 600;
    text-decoration: none;

    &::after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: url('/img/global/arrow-right-light-icon.svg') no-repeat center center;
        margin-left: $spacing-unit-tiny;
    }

    &[target="_blank"] {
        &::after {
            background-image: url('/img/global/external-light-icon.svg');
        }
    }
}