.c-tiles {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: $spacing-unit;
    row-gap: $spacing-unit;

    @include mq($from: tablet) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.c-tile {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--white-color);
    border-radius: 4px;
    padding: $spacing-unit $spacing-unit-large $spacing-unit $spacing-unit;
    border: 1px solid var(--grey-lightest-color);
    box-shadow: $default-shadow;
    overflow: hidden;

    @include mq($from: desktop) {
        padding: $spacing-unit-large 56px $spacing-unit-large $spacing-unit-large;
    }
}

.c-tile__badge {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.4rem;
    line-height: 24px;
    background-color: var(--tile-badge-bg);
    color: var(--tile-badge-color);
    font-weight: bold;
    text-transform: uppercase;
    padding-right: $spacing-unit-small;
    padding-left: $spacing-unit-small;
    border-bottom-left-radius: 8px;
}

.c-tile__main {
    flex: 1;
    min-width: 0;
}

.c-tile__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: $spacing-unit-tiny;

    @include mq($from: desktop) {
        margin-bottom: $spacing-unit;
    }

    > img {
        flex-shrink: 0;
        margin-right: $spacing-unit;
    }
}

.c-tile__title {
    flex: 1;
    min-width: 0;
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: 0;
    color: var(--base-color);
    font-weight: 600;

    @include mq($from: desktop) {
        font-size: 2.4rem;
    }
}

.c-tile__text {
    @include mq($from: tablet) {
        padding-left: 56px;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-tile__action {
    flex-shrink: 0;
    margin-top: $spacing-unit;

    @include mq($from: tablet) {
        padding-left: 56px;
    }
}