﻿// VIEWZ colors

.viewz {
    --brand-darkest-color: #092c27;
    --brand-dark-color: #15665b;
    --brand-color: #1e9282;
    --brand-light-color: #bcdeda;
    --brand-lightest-color: #e9f4f3;
    //
    // Button
    //
    // Brand
    --button-brand-bg-color: var(--brand-color);
    --button-brand-bg-hover-color: var(--brand-dark-color);
    --button-brand-text-color: var(--white-color);
    --button-brand-external-icon: url('/img/viewz/external-light-icon.svg');
    // Dark
    --button-dark-bg-color: var(--brand-darkest-color);
    --button-dark-bg-hover-color: var(--base-color);
    --button-dark-text-color: var(--brand-lightest-color);
    --button-dark-external-icon: url('/img/viewz/external-light-icon.svg');
    // Light
    --button-light-bg-color: var(--brand-lightest-color);
    --button-light-bg-hover-color: var(--brand-light-color);
    --button-light-text-color: var(--brand-darkest-color);
    --button-light-external-icon: url('/img/viewz/external-dark-icon.svg');
    //
    // Gfx
    //
    --gfx-divider: url('/img/viewz/gfx-divider.svg');
    //
    // Side block
    //
    --side-block-brand-inner-bg: var(--brand-lightest-color);
    --side-block-brand-inner-border: var(--brand-color);
    --side-block-brand-side-bg: var(--brand-color);
    --side-block-brand-side-color: var(--white-color);
    --side-block-brand-title-color: var(--brand-darkest-color);
    --side-block-brand-subtitle-color: var(--white-color);

    .c-side-block--brand {
        &::after {
            content: "";
            display: block;
            border-color: var(--brand-color) transparent transparent transparent;
            border-style: solid;
            border-width: 90px 90px 0 0;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
        }

        .c-side-block__cover > img {
            z-index: 20;
        }
    }
    //
    // Side block secondary
    //
    .c-side-block--secondary {
        .c-side-block__inner {
            background-color: #fff;
            border-color: #064F75;

            &::after {
                content: "";
                display: block;
                border-color: transparent #064F75 transparent transparent;
                border-style: solid;
                border-width: 0 90px 90px 0;
                position: absolute;
                right: 0;
                top: 0;
            }
        }

        .c-side-block__side {
            background-color: #064F75;
            color: var(--white-color);
        }

        .c-side-block__subtitle {
            color: var(--white-color);
        }

        span {
            &[style="text-decoration: underline;"] {
                background: linear-gradient(to bottom, transparent 50%, rgba(6, 79, 117, 0.3) 50%);
            }
        }

        .c-course-teaser {
            &::before {
                background: #064F75 url('/img/global/calendar-white-icon.svg') no-repeat center center;
            }
        }

        .c-button {
            background: #012131;
        }
    }
    //
    // Highlights
    //

    --highlights-prev-next-button-bg: #EF8D82;
    --highlights-prev-next-button-color: var(--white-color);
    //
    // Scroll top
    //

    --scroll-top-icon-color: var(--white-color);
    //
    // Tile
    //

    --tile-badge-bg: var(--brand-color);
    --tile-badge-color: var(--white-color);
    //
    // Quotes
    //
    // Brand
    --quotes-brand-bg: var(--brand-color);
    --quotes-brand-color: var(--white-color);
    --quotes-brand-prev-next-button-bg: var(--brand-darkest-color);
    --quotes-brand-prev-next-button-color: var(--white-color);
    // Dark
    --quotes-dark-bg: var(--brand-darkest-color);
    --quotes-dark-color: var(--brand-lightest-color);
    --quotes-dark-prev-next-button-bg: var(--brand-color);
    --quotes-dark-prev-next-button-color: var(--white-color);
    // Light
    --quotes-light-bg: var(--brand-lightest-color);
    --quotes-light-color: var(--brand-darkest-color);
    --quotes-light-prev-next-button-bg: var(--brand-color);
    --quotes-light-prev-next-button-color: var(--white-color);
    //
    // Features
    //

    --feature-teaser-badge-bg: var(--brand-color);
    --feature-teaser-badge-color: var(--white-color);
    //
    // Editions
    //
    --edition-teaser-badge-bg: var(--brand-color);
    --edition-teaser-badge-color: var(--white-color);
    //
    // List cta
    //
    // Brand
    --list-cta-brand-color: var(--white-color);
    --list-cta-brand-bg: var(--brand-color);
    // Dark
    --list-cta-dark-color: var(--brand-lightest-color);
    --list-cta-dark-bg: var(--brand-darkest-color);
    // Light
    --list-cta-light-color: var(--brand-darkest-color);
    --list-cta-light-bg: var(--brand-lightest-color);
    //
    // Grid cta
    //
    // Brand
    --grid-cta-brand-color: var(--white-color);
    --grid-cta-brand-bg: var(--brand-color);
    // Dark
    --grid-cta-dark-color: var(--brand-lightest-color);
    --grid-cta-dark-bg: var(--brand-darkest-color);
    // Light
    --grid-cta-light-color: var(--brand-darkest-color);
    --grid-cta-light-bg: var(--brand-lightest-color);
    // Grey
    --grid-cta-grey-color: var(--base-color);
    --grid-cta-grey-bg: var(--light-bg-color);
    //
    // Block grid
    //
    // Brand
    --block-grid-brand-color: var(--white-color);
    --block-grid-brand-bg: var(--brand-color);
    ;
    // Dark
    --block-grid-dark-color: var(--brand-lightest-color);
    --block-grid-dark-bg: var(--brand-darkest-color);
    // Light
    --block-grid-light-color: var(--brand-darkest-color);
    --block-grid-light-bg: var(--brand-lightest-color);
    // Grey
    --block-grid-grey-color: var(--grey-darkest-color);
    --block-grid-grey-bg: var(--light-bg-color);
    //
    // Related
    //
    --related-teaser-bg: var(--brand-light-color);
    --related-teaser-color: var(--brand-darkest-color);
    --related-teaser-title-color: var(--brand-darkest-color);
    //Promoted
    .c-article-promoted__image {
        border-radius: 0px;
        position: relative;

        &::before {
            content: "";
            display: block;
            border-color: transparent transparent transparent #1e9282;
            border-style: solid;
            border-width: 90px 0 0 90px;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &::after {
            content: "";
            display: block;
            border-color: transparent #EF8D82 transparent transparent;
            border-style: solid;
            border-width: 0 45px 45px 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    //Teaser
    .c-article-teaser__image {
        > img {
            border-radius: 0px;
        }
    }

    .c-header-top__logo-link {
        @include mq($from: wide) {
            margin-left: 0px;
        }
    }

    .c-footer-logo__image {
        @include mq($from: wide) {
            margin-left: 0px;
        }
    }
    // PROMO
    .c-promo-media-viewz {
        @include mq($until: tablet) {
            position: initial;
            padding-top: 0px;
            height: auto;
        }

        @include mq($from: tablet) {
            background: none;
            width: 100%;
            min-width: 1002px;
            top: -160px;
        }

        @include mq($from: desktop) {
            background: none;
            top: -160px;
            width: 100%;
        }
    }

    .c-promo__background {
        &::before {
            content: "";
            display: block;
            border-color: transparent transparent transparent #064F75;
            border-style: solid;
            border-width: 120px 0 0 120px;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &::after {
            content: "";
            display: block;
            border-color: transparent #85D0E8 transparent transparent;
            border-style: solid;
            border-width: 0 90px 90px 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .flickity-page-dots {
        .dot {
            &.is-selected {
                border-color: #EF8D82;

                &::before {
                    background-color: #EF8D82;
                }
            }
        }
    }

    .c-section--light {
        position: relative;

        &::after {
            content: "";
            display: block;
            border-color: transparent var(--brand-light-color) transparent transparent;
            border-style: solid;
            border-width: 493px 493px 0 0;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 0;
        }

        .c-tile {
            z-index: 10;
        }
    }

    .c-cta, .c-tile, .c-side-block__inner, .c-product, .c-button, .c-nav, .c-nav-button, .c-cta-button, .c-carousel,
    .c-edition-teaser, .c-form-submit, .c-form-text, .c-form-email, .c-form-select, .c-form-textarea, .c-logos {
        border-radius: 0px;
    }

    .c-block-grid__row {

        > .o-grid {
            &.o-grid--6\/6,
            &.o-grid--5\/7,
            &.o-grid--7\/5 {
                @include mq($from: desktop) {
                    .c-grid-image {
                        position: relative;

                        &::before {
                            content: "";
                            display: block;
                            border-color: transparent transparent transparent #064F75;
                            border-style: solid;
                            border-width: 90px 0 0 90px;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            top: auto;
                            width: auto;
                            height: auto;
                            z-index: 100;
                            background-color: transparent;
                        }

                        &::after {
                            content: "";
                            display: block;
                            border-color: transparent #EF8D82 transparent transparent;
                            border-style: solid;
                            border-width: 0 45px 45px 0;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }
                }

                > .o-grid__cell {
                    @include mq($from: desktop) {
                        &:first-child {
                            .c-grid-image {
                                &::before {
                                    transform: translate(0px, 0px);
                                    border-radius: 0px;
                                }

                                img {
                                    border-radius: 0px;
                                }
                            }
                        }

                        &:last-child {
                            .c-grid-image {
                                &::before {
                                    transform: translate(0px, 0px);
                                    border-radius: 0px;
                                }

                                img {
                                    border-radius: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}