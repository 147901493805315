﻿// BlueConnect colors

.blueconnect {
    --brand-darkest-color: #1C353D;
    --brand-dark-color: #2F3083;
    --brand-color: #5CB1CA;
    --brand-light-color: #5CB1CA;
    --brand-lightest-color: #EFF7FA;
    --link-color: #A69264;
    --link-dark-color: #7C7054;
    --light-bg-color: #F6F6F6;
    //
    // Button
    //
    // Brand
    --button-brand-bg-color: var(--brand-color);
    --button-brand-bg-hover-color: #4193AB;
    --button-brand-text-color: var(--white-color);
    --button-brand-external-icon: url('/img/blueconnect/external-light-icon.svg');
    // Dark
    --button-dark-bg-color: var(--brand-darkest-color);
    --button-dark-bg-hover-color: #2A5968;
    --button-dark-text-color: var(--brand-lightest-color);
    --button-dark-external-icon: url('/img/blueconnect/external-light-icon.svg');
    // Light
    --button-light-bg-color: var(--brand-lightest-color);
    --button-light-bg-hover-color: var(--brand-light-color);
    --button-light-text-color: var(--brand-darkest-color);
    --button-light-external-icon: url('/img/blueconnect/external-dark-icon.svg');
    //
    // Gfx
    //

    --gfx-divider: url('/img/blueconnect/gfx-divider.svg');
    //
    // Side block
    //

    --side-block-brand-inner-bg: var(--brand-lightest-color);
    --side-block-brand-inner-border: var(--brand-color);
    --side-block-brand-side-bg: var(--brand-light-color);
    --side-block-brand-side-color: var(--white-color);
    --side-block-brand-title-color: var(--brand-darkest-color);
    --side-block-brand-subtitle-color: var(--white-color);


    .c-side-block--secondary {
        .c-side-block__inner {
            background-color: var(--white-color);
            border-color: var(--brand-dark-color);
        }

        .c-side-block__side {
            background-color: var(--brand-dark-color);
        }
    }
    //
    // Highlights
    //

    --highlights-prev-next-button-bg: var(--brand-color);
    --highlights-prev-next-button-color: var(--white-color);
    //
    // Scroll top
    //

    --scroll-top-icon-color: var(--white-color);
    //
    // Tile
    //

    --tile-badge-bg: var(--brand-color);
    --tile-badge-color: var(--white-color);
    //
    // Quotes
    //
    // Brand
    --quotes-brand-bg: var(--brand-color);
    --quotes-brand-color: var(--white-color);
    --quotes-brand-prev-next-button-bg: var(--brand-darkest-color);
    --quotes-brand-prev-next-button-color: var(--white-color);
    // Dark
    --quotes-dark-bg: var(--brand-darkest-color);
    --quotes-dark-color: var(--brand-lightest-color);
    --quotes-dark-prev-next-button-bg: var(--brand-color);
    --quotes-dark-prev-next-button-color: var(--white-color);
    // Light
    --quotes-light-bg: var(--brand-lightest-color);
    --quotes-light-color: var(--brand-darkest-color);
    --quotes-light-prev-next-button-bg: var(--brand-color);
    --quotes-light-prev-next-button-color: var(--white-color);
    //
    // Features
    //

    --feature-teaser-badge-bg: var(--brand-color);
    --feature-teaser-badge-color: var(--white-color);
    //
    // Editions
    //
    --edition-teaser-badge-bg: var(--brand-color);
    --edition-teaser-badge-color: var(--white-color);
    //
    // List cta
    //
    // Brand
    --list-cta-brand-color: var(--white-color);
    --list-cta-brand-bg: var(--brand-color);
    // Dark
    --list-cta-dark-color: var(--brand-lightest-color);
    --list-cta-dark-bg: var(--brand-darkest-color);
    // Light
    --list-cta-light-color: var(--brand-darkest-color);
    --list-cta-light-bg: var(--brand-lightest-color);
    //
    // Grid cta
    //
    // Brand
    --grid-cta-brand-color: var(--white-color);
    --grid-cta-brand-bg: var(--brand-color);
    ;
    // Dark
    --grid-cta-dark-color: var(--brand-lightest-color);
    --grid-cta-dark-bg: var(--brand-darkest-color);
    // Light
    --grid-cta-light-color: var(--brand-darkest-color);
    --grid-cta-light-bg: var(--brand-lightest-color);
    // Grey
    --grid-cta-grey-color: var(--base-color);
    --grid-cta-grey-bg: var(--light-bg-color);
    //
    // Block grid
    //
    // Brand
    --block-grid-brand-color: var(--white-color);
    --block-grid-brand-bg: var(--brand-color);
    ;
    // Dark
    --block-grid-dark-color: var(--brand-lightest-color);
    --block-grid-dark-bg: var(--brand-darkest-color);
    // Light
    --block-grid-light-color: var(--brand-darkest-color);
    --block-grid-light-bg: var(--brand-lightest-color);
    // Grey
    --block-grid-grey-color: var(--grey-darkest-color);
    --block-grid-grey-bg: var(--light-bg-color);
    //
    // Related
    //

    --related-teaser-bg: var(--brand-light-color);
    --related-teaser-color: var(--brand-darkest-color);
    --related-teaser-title-color: var(--brand-darkest-color);

    span {
        &[style="text-decoration: underline;"] {
            background: linear-gradient(to bottom, transparent 60%, rgba(92, 177, 202, 0.3) 50%);
        }
    }

    .c-course-teaser {
        &::before {
            background: var(--brand-dark-color) url('/img/global/calendar-white-icon.svg') no-repeat center center;
        }
    }

    .c-side-block__subtitle {
        color: var(--white-color);
    }

    .c-side-block__body {
        color: var(--white-color);
    }

    .c-button, %c-button {
        background: var(--brand-color);

        &:hover {
            background: var(--button-brand-bg-hover-color);
        }
    }

    .c-button--dark, %c-button--dark {
        background: var(--button-dark-bg-color);
        color: var(--button-dark-text-color);

        &:hover {
            background: var(--button-dark-bg-hover-color);
        }
    }

    .c-side-block__gfx {
        > path:first-child {
            fill: var(--brand-dark-color);
        }

        > path {
            fill: var(--brand-color);
        }
    }

    .c-trump__title {
        > span {
            background: linear-gradient(to bottom, transparent 60%, rgba(92, 177, 202, 0.3) 50%);
        }
    }

    .c-feature-teaser__title-text {
        background: linear-gradient(to bottom, transparent 60%, rgba(92, 177, 202, 0.3) 50%);
    }

    .c-box {
        background-color: #CEE8EF;
    }

    .c-anchor-nav__link {
        &::after {
            background: url('/img/blueconnect/arrow-down-light-icon.svg') no-repeat;
        }
    }
}