.c-site-switcher {
    strong {
        font-weight: 600;
        color: var(--grey-dark-color);
    }
}

.c-site-switcher__trigger {
    display: flex;
    align-items: center;
    appearance: none;
    padding: 0;
    border: 0;
    background: transparent;
    color: var(--grey-dark-color);
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.5;

    > svg {
        margin-left: $spacing-unit-tiny;
    }
}

.c-site-switcher__link {
    color: currentColor;
    text-decoration-color: var(--grey-lightest-color);

    &:hover {
        text-decoration-color: var(--grey-light-color);
    }

    &[target="_blank"] {
        display: inline-flex;
        align-items: center;

        &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url('/img/global/external-light-icon.svg') no-repeat center center;
            margin-left: $spacing-unit-tiny;
        }
    }
}

.c-site-switcher__label {
    @include mq($until: desktop) {
        display: none;
    }

    @include mq($from: desktop) {
        margin-left: $spacing-unit-small;
    }
}

.c-site-switcher__info {
    @include mq($until: desktop) {
        display: none;
    }
}