.c-publication {
    display: flex;
}

.c-publication__side {
    flex-shrink: 0;
    margin-right: $spacing-unit;

    > img {
        border-radius: 4px;
    }
}

.c-publication__main {
    flex: 1;
    min-width: 0;
}

.c-publication__title {
    font-size: 2rem;
    margin-bottom: $spacing-unit-small;
    color: var(--base-color);

    a {
        &::after {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            background: url('/img/global/arrow-right-light-icon.svg') no-repeat center center;
            margin-left: $spacing-unit-tiny;
            vertical-align: middle;
            margin-top: -2px;
        }
    
        &[target="_blank"] {
            &::after {
                background-image: url('/img/global/external-light-icon.svg');
            }
        }
    }
}

.c-publication__description {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-publication__action {
    margin-top: $spacing-unit-small;
}