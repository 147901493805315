.c-list__item {
    position: relative;
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;

    &:nth-child(odd) {
        &::before {
            background-color: var(--light-bg-color);
        }
    }

    &::before {
        content: '';
        display: block;
        width: calc(100% + 192px);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform: translateX(-96px);
        border-top: 2px solid var(--grey-lightest-color);
    }
}