.c-list-cta {
    position: relative;
    padding-top: $spacing-unit-huge;
    padding-bottom: $spacing-unit-huge;

    @include mq($from: tablet) {
        display: flex;
        align-items: center;
    }

    @include mq($from: desktop) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        z-index: -1;
    }
}

.c-list-cta--brand {
    color: var(--list-cta-brand-color);

    &::before {
        background-color: var(--list-cta-brand-bg);
    }

    .c-list-cta__action {
        > * {
            &:nth-child(1) {
                @extend %c-button--dark;
            }
    
            &:nth-child(2) {
                @extend %c-button--light;
            }
        }
    }
}

.c-list-cta--light {
    color: var(--list-cta-light-color);

    &::before {
        background-color: var(--list-cta-light-bg);
    }

    .c-list-cta__action {
        > * {
            &:nth-child(1) {
                @extend %c-button--brand;
            }
    
            &:nth-child(2) {
                @extend %c-button--dark;
            }
        }
    }
}

.c-list-cta--dark {
    color: var(--list-cta-dark-color);

    &::before {
        background-color: var(--list-cta-dark-bg);
    }

    .c-list-cta__action {
        > * {
            &:nth-child(1) {
                @extend %c-button--brand;
            }
    
            &:nth-child(2) {
                @extend %c-button--light;
            }
        }
    }
}

.c-list-cta__main {
    @include mq($from: tablet) {
        flex: 1;
        min-width: 0;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-list-cta__action {
    display: flex;
    flex-wrap: wrap;

    @include mq($until: tablet) {
        margin-top: $spacing-unit-small;
    }

    @include mq($from: tablet) {
        flex-shrink: 0;
        margin-left: 64px;
    }

    @include mq($from: wide) {
        margin-left: 128px;
    }

    > * {
        @extend %c-button;

        + * {
            margin-left: $spacing-unit;
        }
    }
}

.c-list-cta__title {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: $spacing-unit-small;

    @include mq($from: desktop) {
        font-size: 2.4rem;
    }
}