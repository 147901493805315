/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  // font-size: ($inuit-global-font-size / 16px) * 1em; /* [1] */
  // line-height: $inuit-global-line-height / $inuit-global-font-size; /* [1] */
  overflow-y: scroll;
  min-height: 100%;
}
