///* ========================================================================
//   #COLORS
//   ======================================================================== */

:root {
    --white-color: #fff;
    --black-color: #000;
    --base-color: #201e1d;

    --grey-darkest-color: #53575c;
    --grey-dark-color: #74757b;
    --grey-color: #a0a2a5;
    --grey-light-color: #c6c7c9;
    --grey-lightest-color: #ececed;

    --light-bg-color: #f7f7f8;

    --link-dark-color: #328bc8;
    --link-color: #469cd6;
    --link-lightest-color: #E9F1F2;
}