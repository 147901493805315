.c-hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;

    &::before,
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
    }

    &::before {
        background: linear-gradient(180deg, var(--brand-lightest-color) -10.91%, rgba(255, 255, 255, 0) 58.41%);
        top: 0;
    }

    &::after {
        bottom: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
}

.c-hero__image {
    width: 100%;
    height: 550px;
    object-fit: cover;
}