.c-socials {
    display: flex;
}

.c-socials__item {
    + .c-socials__item {
        margin-left: $spacing-unit-tiny;
    }
}

.c-socials__link {
    display: block;
    width: 24px;
    height: 24px;
}