﻿// FinancesConnect colors

.financesconnect {
    --brand-darkest-color: #2a300d;
    --brand-dark-color: #62711f;
    --brand-color: #8ca12c;
    --brand-light-color: #dde3c0;
    --brand-lightest-color: #f4f6ea;
    //
    // Button
    //
    // Brand
    --button-brand-bg-color: var(--brand-color);
    --button-brand-bg-hover-color: var(--brand-dark-color);
    --button-brand-text-color: var(--white-color);
    --button-brand-external-icon: url('/img/financesconnect/external-light-icon.svg');
    // Dark
    --button-dark-bg-color: var(--brand-darkest-color);
    --button-dark-bg-hover-color: var(--base-color);
    --button-dark-text-color: var(--brand-lightest-color);
    --button-dark-external-icon: url('/img/financesconnect/external-light-icon.svg');
    // Light
    --button-light-bg-color: var(--brand-lightest-color);
    --button-light-bg-hover-color: var(--brand-light-color);
    --button-light-text-color: var(--brand-darkest-color);
    --button-light-external-icon: url('/img/financesconnect/external-dark-icon.svg');
    //
    // Gfx
    //

    --gfx-divider: url('/img/financesconnect/gfx-divider.svg');
    //
    // Side block
    //

    --side-block-brand-inner-bg: var(--brand-lightest-color);
    --side-block-brand-inner-border: var(--brand-color);
    --side-block-brand-side-bg: var(--brand-light-color);
    --side-block-brand-side-color: var(--brand-darkest-color);
    --side-block-brand-title-color: var(--brand-darkest-color);
    --side-block-brand-subtitle-color: var(--brand-darkest-color);
    //
    // Highlights
    //

    --highlights-prev-next-button-bg: var(--brand-color);
    --highlights-prev-next-button-color: var(--white-color);
    //
    // Scroll top
    //

    --scroll-top-icon-color: var(--white-color);
    //
    // Tile
    //

    --tile-badge-bg: var(--brand-color);
    --tile-badge-color: var(--white-color);
    //
    // Quotes
    //
    // Brand
    --quotes-brand-bg: var(--brand-color);
    --quotes-brand-color: var(--white-color);
    --quotes-brand-prev-next-button-bg: var(--brand-darkest-color);
    --quotes-brand-prev-next-button-color: var(--white-color);
    // Dark
    --quotes-dark-bg: var(--brand-darkest-color);
    --quotes-dark-color: var(--brand-lightest-color);
    --quotes-dark-prev-next-button-bg: var(--brand-color);
    --quotes-dark-prev-next-button-color: var(--white-color);
    // Light
    --quotes-light-bg: var(--brand-lightest-color);
    --quotes-light-color: var(--brand-darkest-color);
    --quotes-light-prev-next-button-bg: var(--brand-color);
    --quotes-light-prev-next-button-color: var(--white-color);
    //
    // Features
    //

    --feature-teaser-badge-bg: var(--brand-color);
    --feature-teaser-badge-color: var(--white-color);
    //
    // Editions
    //
    --edition-teaser-badge-bg: var(--brand-color);
    --edition-teaser-badge-color: var(--white-color);
    //
    // List cta
    //
    // Brand
    --list-cta-brand-color: var(--white-color);
    --list-cta-brand-bg: var(--brand-color);
    // Dark
    --list-cta-dark-color: var(--brand-lightest-color);
    --list-cta-dark-bg: var(--brand-darkest-color);
    // Light
    --list-cta-light-color: var(--brand-darkest-color);
    --list-cta-light-bg: var(--brand-lightest-color);
    //
    // Grid cta
    //
    // Brand
    --grid-cta-brand-color: var(--white-color);
    --grid-cta-brand-bg: var(--brand-color);
    ;
    // Dark
    --grid-cta-dark-color: var(--brand-lightest-color);
    --grid-cta-dark-bg: var(--brand-darkest-color);
    // Light
    --grid-cta-light-color: var(--brand-darkest-color);
    --grid-cta-light-bg: var(--brand-lightest-color);
    // Grey
    --grid-cta-grey-color: var(--base-color);
    --grid-cta-grey-bg: var(--light-bg-color);
    //
    // Block grid
    //
    // Brand
    --block-grid-brand-color: var(--white-color);
    --block-grid-brand-bg: var(--brand-color);
    ;
    // Dark
    --block-grid-dark-color: var(--brand-lightest-color);
    --block-grid-dark-bg: var(--brand-darkest-color);
    // Light
    --block-grid-light-color: var(--brand-darkest-color);
    --block-grid-light-bg: var(--brand-lightest-color);
    // Grey
    --block-grid-grey-color: var(--grey-darkest-color);
    --block-grid-grey-bg: var(--light-bg-color);
    //
    // Related
    //

    --related-teaser-bg: var(--brand-light-color);
    --related-teaser-color: var(--brand-darkest-color);
    --related-teaser-title-color: var(--brand-darkest-color);

    .c-header-top__image {
        @include mq($from: desktop) {
            height: 90px;
        }
    }
}