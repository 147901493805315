.c-popover {
    position: relative;
}

.c-popover__trigger {
    &.is-open {
        + .c-popover__content {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.c-popover__content {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;

    @include mq($until: desktop) {
        transform: translateX(-12px);
    }
}