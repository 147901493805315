// SETTINGS
@import "settings/settings.config";
@import 'settings/settings.spacings';
@import "settings/settings.colors";
@import "settings/settings.theme";
@import "settings/settings.effects";
@import "settings/settings.global";
@import 'settings/settings.mixins';
@import "vendors/inuitcss/settings/settings.core";

// TOOLS
@import "vendors/inuitcss/tools/tools.clearfix";
@import "vendors/inuitcss/tools/tools.font-size";
@import "vendors/inuitcss/tools/tools.hidden";
@import "../../node_modules/sass-mq/mq";

// GENERIC
@import "vendors/inuitcss/generic/generic.box-sizing";
@import "vendors/inuitcss/generic/generic.normalize";
@import "vendors/inuitcss/generic/generic.reset";
@import "vendors/inuitcss/generic/generic.shared";

// ELEMENTS
@import "vendors/inuitcss/elements/elements.page";

// LAYOUT
@import "layout/_l-content.scss";
@import "layout/_l-footer.scss";
@import "layout/_l-header.scss";
@import "layout/_l-top.scss";
@import "layout/_l-wrap.scss";

// PAGES
@import "pages/_pages.error-page.scss";

// BASE
@import "base/base.generic";

// OBJECTS
@import "objects/_o-grid.scss";
@import "vendors/inuitcss/objects/objects.grid";

// COMPONENTS
@import "components/_c-article.scss";
@import "components/_c-block-grid.scss";
@import "components/_c-block-list.scss";
@import "components/_c-block.scss";
@import "components/_c-body.scss";
@import "components/_c-box.scss";
@import "components/_c-breadcrumb.scss";
@import "components/_c-button.scss";
@import "components/_c-callout.scss";
@import "components/_c-cards.scss";
@import "components/_c-carousel.scss";
@import "components/_c-checkbox.scss";
@import "components/_c-contact.scss";
@import "components/_c-container.scss";
@import "components/_c-content.scss";
@import "components/_c-courses.scss";
@import "components/_c-cta.scss";
@import "components/_c-dividers.scss";
@import "components/_c-editions.scss";
@import "components/_c-editors.scss";
@import "components/_c-features.scss";
@import "components/_c-field.scss";
@import "components/_c-form.scss";
@import "components/_c-grid-cta.scss";
@import "components/_c-grid-logos.scss";
@import "components/_c-grid-rte.scss";
@import "components/_c-hero-gradient.scss";
@import "components/_c-hero.scss";
@import "components/_c-leadin.scss";
@import "components/_c-link.scss";
@import "components/_c-list-cta.scss";
@import "components/_c-list.scss";
@import "components/_c-logos.scss";
@import "components/_c-members.scss";
@import "components/_c-nav.scss";
@import "components/_c-page-cta.scss";
@import "components/_c-pager.scss";
@import "components/_c-popover.scss";
@import "components/_c-popup.scss";
@import "components/_c-portals.scss";
@import "components/_c-products.scss";
@import "components/_c-promo.scss";
@import "components/_c-publication.scss";
@import "components/_c-quotes.scss";
@import "components/_c-related.scss";
@import "components/_c-scroll-top.scss";
@import "components/_c-search.scss";
@import "components/_c-section.scss";
@import "components/_c-side-block.scss";
@import "components/_c-site-switcher.scss";
@import "components/_c-socials.scss";
@import "components/_c-subscriber-modal.scss";
@import "components/_c-tiles.scss";
@import "components/_c-top-nav.scss";
@import "components/_c-trumps.scss";
@import "components/_c-umb-grid.scss";

// THEMES
@import "themes/_themes.blueconnect.scss";
@import "themes/_themes.cpasconnect.scss";
@import "themes/_themes.dgconnect.scss";
@import "themes/_themes.financesconnect.scss";
@import "themes/_themes.finconnect.scss";
@import "themes/_themes.hrmconnect.scss";
@import "themes/_themes.matconnect.scss";
@import "themes/_themes.omgevingconnect.scss";
@import "themes/_themes.orangeconnect.scss";
@import "themes/_themes.oranjeconnect.scss";
@import "themes/_themes.viewz.scss";

// Animate on scroll
.no-js {
    [data-sal|='fade'] {
        opacity: 1;
    }

    [data-sal|='slide'],
    [data-sal|='zoom'] {
        opacity: 1;
        transform: none;
    }

    [data-sal|='flip'] {
        transform: none;
    }
}

// UTILITIES
@import "vendors/inuitcss/utilities/utilities.clearfix";
@import "vendors/inuitcss/utilities/utilities.widths";
@import "utilities/utilities.widths";
@import "vendors/inuitcss/utilities/utilities.hide";
@import "utilities/utilities.helpers";