.c-members {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 64px;
    row-gap: $spacing-unit-large;

    @include mq($from: desktop) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 112px;
        row-gap: 64px;
    }
}

.c-member {
    display: flex;

    @include mq($from: desktop) {
        &:nth-child(odd) {
            flex-direction: row-reverse;
            text-align: right;
    
            .c-member__image {
                margin-left: $spacing-unit;
            }
        }
    
        &:nth-child(even) {
            .c-member__image {
                margin-right: $spacing-unit;
            }
        }
    }
}

.c-member__image {
    flex-shrink: 0;

    @include mq($until: desktop) {
        margin-right: $spacing-unit;
    }

    > img {
        border-radius: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }
}

.c-member__main {
    flex: 1;
    min-width: 0;
}

.c-member__title {
    margin-bottom: $spacing-unit-tiny;
    color: var(--base-color);
    font-size: 2rem;
    line-height: 24px;
    display: inline-flex;
}

.c-member-linkedin {
    flex-shrink: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 4px;
}

.c-member__meta {
    color: var(--grey-dark-color);

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-member__action {
    margin-top: $spacing-unit-small;
}