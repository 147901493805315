.c-block-list__row {
    &:not(:first-child) {
        margin-top: $spacing-unit-huge;

        @include mq($from: desktop) {
            margin-top: 80px;
        }
    }
}

.c-block-list__row--stick {
    &:last-child {
        margin-bottom: -$spacing-unit-huge;

        @include mq($from: desktop) {
            margin-bottom: -80px;
        }
    }

    + .c-block-list__row--stick {
        margin-top: 0;
    }
}

.c-block-list__row--tint-light,
.c-block-list__row--tint-light-grey {
    position: relative;
    padding-top: $spacing-unit-huge;
    padding-bottom: $spacing-unit-huge;

    @include mq($from: desktop) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        z-index: -1;
    }
}

.c-block-list__row--tint-light {
    color: var(--base-color);

    &::before {
        background-color: var(--brand-lightest-color);
    }
}

.c-block-list__row--tint-light-grey {
    &::before {
        background-color: var(--light-bg-color);
    }
}

//
// Components
//

// Anchors

.c-anchor-nav {
    min-height: 64px;
    position: relative;
    text-align: center;

    @include mq($from: tablet) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &::before {
        content: '';
        display: block;
        border-top: 1px solid var(--grey-lightest-color);
        border-bottom: 1px solid var(--grey-lightest-color);
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background: var(--white-color);
        z-index: -1;
    }

    + .c-promo {
        @include mq($until: tablet) {
            margin-top: $spacing-unit;
        }
    }
}

.c-anchor-nav__link {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    line-height: 14px;
    color: var(--link-color);
    font-weight: 600;
    padding-top: $spacing-unit-small;
    padding-bottom: $spacing-unit-small;

    @include mq($from: tablet) {
        padding: $spacing-unit $spacing-unit-small;

        &:not(:first-child) {
            margin-left: $spacing-unit-tiny;
        }
    
        &:not(:last-child) {
            margin-right: $spacing-unit-tiny;
        }
    }

    &::after {
        content: '';
        display: block;
        width: 10px;
        height: 16px;
        margin-left: $spacing-unit-small;
        background: url('/img/global/arrow-down-light-icon.svg') no-repeat;
    }
}

// Full image

.c-full-media {
    position: relative;
    height: 320px;

    @include mq($from: desktop) {
        height: 480px;
    }
}

.c-full-media__image {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}