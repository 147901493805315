.c-gfx-divider {
    position: relative;
    padding-top: 48px;
    padding-bottom: 48px;

    &::after {
        content: '';
        display: block;
        width: 72px;
        height: 9px;
        background: var(--gfx-divider);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}