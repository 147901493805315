.c-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: $spacing-unit;
    row-gap: $spacing-unit;

    @include mq($from: tablet) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: $spacing-unit-huge;
        row-gap: $spacing-unit-huge;
    }
}

.c-card__title {
    font-weight: 600;
    position: relative;
    margin-bottom: 0;
    padding-left: $spacing-unit;
    color: var(--base-color);
    
    &::before {
        content: '';
        display: block;
        width: 4px;
        height: 100%;
        background-color: var(--brand-color);
        position: absolute;
        left: 0;
        top: 0;

        @at-root .c-block-grid__row--brand & {
            background-color: var(--brand-light-color);
        }
    }
}

.c-card__suffix {
    padding-top: 4px;
    padding-left: $spacing-unit;
    color: var(--grey-color);
}

.c-card__main {
    margin-top: $spacing-unit-small;
    padding-left: $spacing-unit;
}

.c-card__body {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-card__action {
    margin-top: $spacing-unit;
}