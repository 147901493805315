.c-section {
    &:not(.c-section--no-padding) {
        padding-top: 40px;
        padding-bottom: 40px;

        @include mq($from: desktop) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }
}

.c-section--light {
    background-color: var(--brand-lightest-color);
    color: var(--base-color);
}

.c-section--light-grey {
    background-color: var(--light-bg-color);
}

.c-section__inner {
    max-width: 1328px;
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
}

.c-section-logo {
    text-align: center;
    margin-bottom: $spacing-unit-large;
}

.c-section-logo__image {
    display: inline-block;
}

.c-section-title {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 600;
    color: var(--base-color);
    margin-bottom: 32px;

    @include mq($from: desktop) {
        font-size: 3.2rem;
        margin-bottom: 48px;
    }
}

.c-section-title--large {
    font-size: 3rem;
    line-height: 1.25;

    @include mq($from: desktop) {
        font-size: 4rem;
    }
}

.c-section-title--centered {
    text-align: center;
    max-width: 860px;
    margin-right: auto;
    margin-left: auto;
}

.c-section-title--extra-margin {
    margin-bottom: 48px;

    @include mq($from: desktop) {
        margin-bottom: 64px;
    }
}

.c-section-subtitle {
    font-size: 2.6rem;
    line-height: 1.3;
    font-weight: 600;
}

.c-section-intro {
    font-size: 2.2rem;
    line-height: 1.4;
    margin-bottom: $spacing-unit;

    @include mq($from: desktop) {
        margin-bottom: $spacing-unit-huge;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-section-intro--centered {
    max-width: 768px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.c-section-image {
    margin-bottom: $spacing-unit;

    @include mq($from: desktop) {
        margin-bottom: $spacing-unit-huge;
    }
}