.c-editions__title {
    text-align: center;
}

.c-editions__footer {
    margin-top: $spacing-unit-huge;
    text-align: center;
}

.c-editions-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: $spacing-unit;
    row-gap: $spacing-unit;

    @include mq($from: tablet) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: desktop) {
        max-width: 912px;
        margin-right: auto;
        margin-left: auto;
    }

    &:not(:last-child) {
        margin-bottom: $spacing-unit-large;

        @include mq($from: desktop) {
            margin-bottom: $spacing-unit-huge;
        }
    }
}

.c-edition-teaser {
    display: flex;
    background-color: var(--light-bg-color);
    padding: 2px;
    overflow: hidden;
    border-radius: 4px;
}

.c-edition-teaser__image {
    background-color: var(--white-color);
    flex-shrink: 0;
    width: 90px;

    img {
        border-radius: 3px 0 0 3px;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.c-edition-teaser__main {
    position: relative;
    flex: 1;
    min-width: 0;
    padding: $spacing-unit;
}

.c-edition-teaser__badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(2px, -2px);
    font-size: 1.4rem;
    line-height: 24px;
    background-color: var(--edition-teaser-badge-bg);
    color: var(--edition-teaser-badge-color);
    font-weight: bold;
    text-transform: uppercase;
    padding-right: $spacing-unit-small;
    padding-left: $spacing-unit-small;
    border-bottom-left-radius: 8px;
}

.c-edition-teaser__meta {
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grey-color);
    margin-bottom: $spacing-unit-tiny;
}

.c-edition-teaser__link {
    display: block;
    position: relative;
    font-weight: bold;
    color: var(--base-color);
    padding-right: $spacing-unit-huge;

    &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background: url('/img/global/arrow-right-light-icon.svg') no-repeat center center;
    }

    &[target="_blank"] {
        &::after {
            background-image: url('/img/global/external-light-icon.svg');
        }
    }
}

.c-edition-teaser__link-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}