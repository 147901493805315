.c-trumps {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 112px;
    row-gap: $spacing-unit;

    @include mq($from: desktop) {
        grid-template-columns: repeat(2, 1fr);
    }

    + .c-trumps {
        margin-top: $spacing-unit;
    }
}

.c-trumps--highlighted {
    .c-trump {
        position: relative;
        padding-top: $spacing-unit;
        padding-bottom: $spacing-unit;

        &::before {
            content: '';
            display: block;
            background-color: var(--light-bg-color);
            width: calc(100% + 48px);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-24px);
            z-index: -1;
            border-radius: 16px 4px 32px 4px;
        }
    }

    .c-trump__title {    
        > span {
            background: linear-gradient(to bottom, transparent 50%, var(--grey-lightest-color) 50%);
        }
    }
}

.c-trump {
    display: flex;
}

.c-trump__icon {
    width: 32px;
    flex-shrink: 0;
    margin-right: $spacing-unit-small;

    > img {
        width: 32px;
        height: auto;
    }
}

.c-trump__main {
    flex: 1;
    min-width: 0;
}

.c-trump__title {
    font-weight: 600;
    color: var(--base-color);
    margin-bottom: $spacing-unit-small;

    @at-root .c-block-grid__row--dark & {
        color: var(--brand-lightest-color);
    }

    > span {
        background: linear-gradient(to bottom, transparent 50%, var(--light-bg-color) 50%);

        @at-root .c-block-grid__row--brand & {
            background: linear-gradient(to bottom, transparent 50%, var(--brand-light-color) 50%);
        }

        @at-root .c-block-grid__row--dark & {
            background: linear-gradient(to bottom, transparent 50%, var(--brand-dark-color) 50%);
        }

        @at-root .c-block-grid__row--light & {
            background: linear-gradient(to bottom, transparent 50%, var(--brand-light-color) 50%);
        }

        @at-root .c-block-grid__row--grey & {
            background: linear-gradient(to bottom, transparent 50%, var(--grey-lightest-color) 50%);
        }
    }
}

.c-trump__body {
    padding-right: $spacing-unit;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}