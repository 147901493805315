.c-quotes {
    border-radius: 24px;
    padding: $spacing-unit 80px;

    @include mq($from: tablet) {
        padding: 64px 96px;
    }

    b,
    strong {
        color: inherit;
    }
}

.c-quotes--brand {
    background-color: var(--quotes-brand-bg);
    color: var(--quotes-brand-color);

    .c-quotes-carousel {
        .flickity-prev-next-button {
            background: var(--quotes-brand-prev-next-button-bg);
            color: var(--quotes-brand-prev-next-button-color);
        }
    }
}

.c-quotes--dark {
    background-color: var(--quotes-dark-bg);
    color: var(--quotes-dark-color);

    .c-quotes-carousel {
        .flickity-prev-next-button {
            background: var(--quotes-dark-prev-next-button-bg);
            color: var(--quotes-dark-prev-next-button-color);
        }
    }
}

.c-quotes--light {
    background-color: var(--quotes-light-bg);
    color: var(--quotes-light-color);

    .c-quotes-carousel {
        .flickity-prev-next-button {
            background: var(--quotes-light-prev-next-button-bg);
            color: var(--quotes-light-prev-next-button-color);
        }
    }
}

.c-quotes-carousel {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;

    .flickity-viewport {
        transition: height 0.2s;
    }

    .flickity-prev-next-button {
        background: var(--brand-color);
        color: var(--brand-darkest-color);
        width: 40px;
        height: 40px;
        box-shadow: 0 1px 3px rgba(32, 30, 29, 0.14), 0 4px 6px rgba(32, 30, 29, 0.1);
        padding: 0;
        border-radius: 100%;
        overflow: hidden;

        @include mq($from: wide) {
            width: 48px;
            height: 48px;
        }

        &.previous {
            left: -56px;

            @include mq($from: desktop) {
                left: -80px;
            }
        }

        &.next {
            right: -56px;

            @include mq($from: desktop) {
                right: -80px;
            }
        }
    }
    
    .flickity-button-icon {
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
    }

    .flickity-button {
        &:disabled {
            display: none;
        }
    }
}

.c-quotes-carousel__cell {
    width: 100%;
}

.c-quote {
    display: flex;
    align-items: center;
}

.c-quote__image {
    @include mq($until: tablet) {
        display: none;
    }

    @include mq($from: tablet) {
        flex-shrink: 0;
        margin-right: $spacing-unit-huge;
        width: 140px;
        aspect-ratio: 1 / 1;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }
}

.c-quote__main {
    flex: 1;
    min-width: 0;
}

.c-quote__body {
    font-style: italic;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-quote__meta {
    font-size: 1.4rem;
    margin-top: $spacing-unit-small;
}