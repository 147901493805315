.c-breadcrumb {
    display: flex;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-top: -48px;
    margin-bottom: $spacing-unit;

    @include mq($until: desktop) {
        margin-top: -12px;
    }
}

.c-breadcrumb__link {
    display: flex;
    align-items: center;
    color: var(--grey-color);
    text-decoration: none;
    font-weight: 600;

    > svg {
        margin-right: $spacing-unit-small;
    }
}