// Articles

.c-articles__footer {
    border-top: 2px solid var(--light-bg-color);
    margin-top: $spacing-unit;
    padding-top: $spacing-unit;
}

// Article

.c-article-title {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 600;
    color: var(--base-color);
    margin-bottom: $spacing-unit;

    @include mq($from: desktop) {
        font-size: 3.2rem;
        max-width: 840px;
    }
}

.c-article__content {
    position: relative;
    margin-top: $spacing-unit-large;

    @include mq($from: desktop) {
        margin-top: $spacing-unit-huge;
        min-height: 152px;
    }
}

// Teaser

.c-article-teaser {
    display: flex;

    &:not(:last-child) {
        .c-article-teaser__main {
            padding-bottom: $spacing-unit;
            border-bottom: 2px solid var(--light-bg-color);
        }
    }

    + .c-article-teaser {
        margin-top: $spacing-unit;
    }
}

.c-article-teaser__image {
    flex-shrink: 0;
    width: 114px;
    margin-right: $spacing-unit;

    @include mq($from: tablet) {
        width: 258px;
    }

    > img {
        border-radius: 4px 4px 24px 4px;
        background-color: var(--grey-lightest-color);

        @include mq($from: tablet) {
            width: 100%;
        }
    }
}

.c-article-teaser__main {
    flex: 1;
    min-width: 0;

    @include mq($from: desktop) {
        padding-right: $spacing-unit;
    }
}

.c-article-teaser__title {
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: $spacing-unit-tiny;
    
    a {
        display: inline-block;
        padding-right: 28px;
        color: var(--base-color);
        text-decoration-color: var(--grey-lightest-color);

        &::after {
            content: '';
            padding-right: 12px;
            padding-left: 12px;
            background: url('/img/global/arrow-right-light-icon.svg') no-repeat center center;
            margin-left: 4px;
        }
    
        &[target="_blank"] {
            &::after {
                background-image: url('/img/global/external-light-icon.svg');
            }
        }

        &:hover {
            text-decoration-color: var(--grey-light-color);
        }
    }
}

.c-article-teaser__body {
    margin-top: $spacing-unit-tiny;

    @include mq($until: tablet) {
        display: none;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-article-teaser__action {
    margin-top: $spacing-unit-small;
}

// Promoted

.c-article-promoted {
    background-color: var(--white-color);
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

.c-article-promoted__image {
    border-radius: 4px 4px 32px 16px;
    overflow: hidden;

    > img {
        background-color: var(--grey-lightest-color);
        height: 300px;
        width: 100%;
        object-fit: cover;
    }
}

.c-article-promoted__main {
    @include mq($until: desktop) {
        margin-top: $spacing-unit-large;

    }

    @include mq($from: desktop) {
        padding-top: $spacing-unit-large;
        padding-right: 56px;
        padding-bottom: $spacing-unit-large;
    }
}

.c-article-promoted__title {
    font-size: 2.6rem;
    line-height: 1.2;
    margin-bottom: $spacing-unit-small;

    a {
        display: inline-block;
        padding-right: 28px;
        color: var(--base-color);
        text-decoration-color: var(--grey-lightest-color);

        &::after {
            content: '';
            padding-right: 12px;
            padding-left: 12px;
            background: url('/img/global/arrow-right-light-icon.svg') no-repeat center center;
            margin-left: 4px;
        }
    
        &[target="_blank"] {
            &::after {
                background-image: url('/img/global/external-light-icon.svg');
            }
        }

        &:hover {
            text-decoration-color: var(--grey-light-color);
        }
    }
}

.c-article-promoted__body {
    margin-top: $spacing-unit-small;
}

.c-article-promoted__action {
    margin-top: $spacing-unit-small;
}

// Related

.c-related-article-teaser-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: $spacing-unit;
    row-gap: $spacing-unit;

    @include mq($from: tablet) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: desktop) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.c-related-article-teaser__image {
    position: relative;
    margin-bottom: $spacing-unit-small;
    border-radius: 4px;
    overflow: hidden;

    > .c-article-labels {
        margin-bottom: 0;
        position: absolute;
        bottom: $spacing-unit-small;
        left: $spacing-unit-small;
    }
}

.c-related-article-teaser__title {
    font-size: 1.8rem;
    line-height: 1.44;
    margin-bottom: $spacing-unit-tiny;
    
    a {
        color: var(--base-color);
        text-decoration-color: var(--grey-lightest-color);

        &:hover {
            text-decoration-color: var(--grey-light-color);
        }
    }
}

// Subcomponents

.c-article-labels {
    display: flex;

    + .c-article-teaser__title,
    + .c-article-promoted__title {
        margin-top: $spacing-unit-small;
    }

    + .c-related-item__title {
        margin-top: 4px;
    }
}

.c-article-labels__item {
    font-size: 1rem;
    line-height: 16px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    background-color: var(--grey-lightest-color);
    padding-right: 8px;
    border-radius: 8px;
}

.c-article-labels__item--subscriber {
    background-color: var(--brand-light-color);
    color: var(--brand-darkest-color);
    padding-left: 22px;
    min-width: 16px;
    min-height: 16px;

    > svg {
        position: absolute;
        top: 0;
        left: 0;

        path:first-child {
            fill: var(--brand-color);
        }
    }
}

.c-article-labels__item--collapsible {
    @include mq($until: tablet) {
        padding-left: 0;
    }

    .c-article-labels__item-label {
        @include mq($until: tablet) {
            display: none;
        }
    }
}

.c-article-labels__item--tag {
    padding-left: 18px;

    &::before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 4px;
        left: 4px;
        background-color: var(--white-color);
        border-radius: 100%;
    }
}

.c-article-labels__divider {
    width: 1px;
    background-color: var(--grey-lightest-color);
    margin-right: 10px;
    margin-left: 10px;
}

.c-article-meta {
    display: flex;
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grey-color);

    @include mq($until: desktop) {
        @at-root .c-articles & {
            display: none;
        }
    }
}

.c-article-meta__item {
    &:not(:only-child) {
        &:first-child {
            flex-shrink: 0;
        }
    }

    + .c-article-meta__item {
        margin-left: $spacing-unit;
    }
}

// Share

.c-article-share {
    display: flex;

    @include mq($until: wide) {
        margin-top: $spacing-unit-large;
    }

    @include mq($from: wide) {
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(calc(-100% - #{$spacing-unit-large}));
    }
}

.c-article-share__item {
    + .c-article-share__item {
        @include mq($until: wide) {
            margin-left: $spacing-unit-tiny; 
        }

        @include mq($from: wide) {
            margin-top: $spacing-unit-tiny;
        }   
    }
}

.c-article-share__link,
.c-article-share__button {
    display: block;
    width: 24px;
    height: 24px;
}

.c-article-share__button {
    appearance: none;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
}

.c-article-share__brand-icon {
    path {
        &:first-child {
            fill: var(--brand-color);
        }
    }
}