.c-scroll-top {
    display: none;
    outline: 0;
    width: 44px;
    height: 44px;
    padding: 0;
    border-radius: 100%;
    background-color: var(--brand-color);
    position: fixed;
    z-index: 1500;
    right: $spacing-unit;
    bottom: $spacing-unit;
    border: none;
    box-shadow: 0 1px 3px rgba(32, 30, 29, 0.14), 0 4px 6px rgba(32, 30, 29, 0.1);
    cursor: pointer;

    > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
            fill: var(--scroll-top-icon-color);
        }
    }
}