html,
body {
  height: 100%;
}

html {
  font-size: 10px;
  overflow-y: visible;

  @include mq($until: wide) {
    font-size: 9px;
  }
}

body {
  font-size: 1.8rem;
  line-height: 1.44;
  font-family: $base-font-family;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--grey-darkest-color);
  min-width: 375px;

  &.is-menu-open {
    @include mq($until: desktop) {
        overflow: hidden;
        padding-right: var(--scrollbarWidth, 15px); // Fix scrollbar jump
    }
  }

  &.has-popup {
    overflow: hidden;
    padding-right: var(--scrollbarWidth, 15px); // Fix scrollbar jump
  }
}

.js-preload * {
  transition: none !important;
}

a {
  color: inherit;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

p {
  margin-top: 0;
  margin-bottom: $spacing-unit-small;
}

b,
strong {
  font-weight: 900;
  color: currentColor;
}

input,
select,
textarea,
button {
  color: var(--base-color);
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $base-font-family;
  font-weight: 600;
}

h2 {
  font-size: 2.8rem;
  line-height: 1.2;

  @include mq($from: desktop) {
    font-size: 3.2rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: 1.3;

  @include mq($from: desktop) {
    font-size: 2.4rem;
  }
}

h4 {
  font-size: 1.8rem;
  line-height: 1.3;

  @include mq($from: desktop) {
    font-size: 2rem;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  object-fit: cover;
}

// Underline

span {
  &[style="text-decoration: underline;"] {
      text-decoration: none !important;
      background: linear-gradient(to bottom, transparent 60%, var(--brand-light-color) 50%);
  }
}

blockquote {
    font-style: italic;
    margin-left: $spacing-unit-large;
}