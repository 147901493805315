.c-button,
%c-button {
    font-size: 1.6rem;
    line-height: 24px;
    display: inline-flex;
    font-weight: 600;
    appearance: none;
    box-shadow: 0 1px 3px rgba(32, 30, 29, 0.14), 0 4px 6px rgba(32, 30, 29, 0.1);
    border: 0;
    border-radius: 4px;
    background: var(--grey-darkest-color);
    text-decoration: none;
    padding: $spacing-unit-tiny $spacing-unit-small;
    color: var(--white-color);
    transition: transform 0.2s ease;

    &[target="_blank"] {
        &::after {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background: url('/img/global/external-white-icon.svg') no-repeat center center;
            margin-left: 4px;
        }
    }

    &:hover {
        background: var(--base-color);
    }
}

.c-button--brand,
%c-button--brand {
    background: var(--button-brand-bg-color);
    color: var(--button-brand-text-color);

    &[target="_blank"] {
        &::after {
            background-image: var(--button-brand-external-icon);
        }
    }

    &:hover {
        background: var(--button-brand-bg-hover-color);
    }
}

.c-button--dark,
%c-button--dark {
    background: var(--button-dark-bg-color);
    color: var(--button-dark-text-color);

    &[target="_blank"] {
        &::after {
            background-image: var(--button-dark-external-icon);
        }
    }

    &:hover {
        background: var(--button-dark-bg-hover-color);
    }
}

.c-button--light,
%c-button--light {
    background: var(--button-light-bg-color);
    color: var(--button-light-text-color);

    &[target="_blank"] {
        &::after {
            background-image: var(--button-light-external-icon);
        }
    }

    &:hover {
        background: var(--button-light-bg-hover-color);
    }
}

.c-button--link,
%c-button--link {
    background: var(--link-color);
    color: var(--white-color);

    &[target="_blank"] {
        &::after {
            background-image: var(--button-external-light-icon);
        }
    }

    &:hover {
        background: var(--link-dark-color);
    }
}