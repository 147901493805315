.c-field {
    display: flex;

    + .c-field {
        margin-top: $spacing-unit;
    }
}

.c-field__icon {
    flex-shrink: 0;
    margin-right: $spacing-unit-small;
}

.c-field__value {
    flex: 1;
    min-width: 0;
}

.c-field-meta {
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grey-dark-color);
    margin-top: $spacing-unit-tiny;
}