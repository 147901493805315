.c-subscriber-modal {
    text-align: center;
    color: var(--grey-darkest-color);
}

.c-subscriber-modal__header {
    text-align: center;
    margin-bottom: $spacing-unit;
}

.c-subscriber-modal__badge {
    position: relative;
    background-color: var(--brand-light-color);
    color: var(--brand-darkest-color);
    display: inline-flex;
    align-items: center;
    height: 24px;
    border-radius: 12px;
    padding-left: 32px;
    padding-right: 16px;

    > svg {
        position: absolute;
        top: 0;
        left: 0;

        path:first-child {
            fill: var(--brand-color);
        }
    }
}

.c-subscriber-modal__badge-label {
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
}

.c-subscriber-modal__title {
    font-size: 2rem;
    line-height: 1.3;
    color: var(--base-color);
    margin-bottom: $spacing-unit-small;

    @include mq($from: desktop) {
        font-size: 2.4rem;
    }
}

.c-subscriber-modal__subtitle {
    font-size: 2rem;
    line-height: 1.3;
    color: var(--base-color);
    margin-top: $spacing-unit-large;
    margin-bottom: $spacing-unit-tiny;
}

.c-subscriber-modal__checkbox {
    margin-top: $spacing-unit-small;
}

.c-subscriber-modal__action {
    margin-top: $spacing-unit;
}

// Subcomponents

.c-check-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    + .c-check-list {
        margin-top: $spacing-unit-tiny;
    }
}

.c-check-list__item {
    display: flex;
    align-items: center;
    margin: 0 $spacing-unit-small;
    padding: 0;
}

.c-check-list__icon {
    flex-shrink: 0;
    margin-right: $spacing-unit-tiny;
}