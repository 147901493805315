.l-header {
    @at-root .is-front & {
        margin-bottom: $spacing-unit;
    }
}

.l-header__inner {
    max-width: 1328px;
    margin-right: auto;
    margin-left: auto;

    @include mq($from: tablet) {
        padding-right: $spacing-unit;
        padding-left: $spacing-unit;
    }
}

// Components

.c-header-top {
    display: flex;
    justify-content: space-between;
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;

    @include mq($until: tablet) {
        padding-right: $spacing-unit;
        padding-left: $spacing-unit;
    }

    @include mq($from: desktop) {
        align-items: center;
        padding-top: 48px;
        padding-bottom: 48px;
    }
}

.c-header-top__logo-link {
    display: block;

    @include mq($from: wide) {
        margin-left: -40px;
    }
}

.c-header-top__image {
    @include mq($from: desktop) {
        width: 420px;
        height: 106px;
    }
}

.c-header-top__link {
    @include mq($until: desktop) {
        display: none;
    }
}

.c-header-top__language {
    background: var(--white-color);
    font-size: 1.6rem;
    line-height: 24px;
    font-weight: 600;
    appearance: none;
    box-shadow: 0 1px 3px rgba(32, 30, 29, 0.14), 0 4px 6px rgba(32, 30, 29, 0.1);
    border: 0;
    border-radius: 4px;
    padding: $spacing-unit-tiny $spacing-unit-small;
    color: var(--grey-color);
    max-height: 40px;
    margin-left: $spacing-unit-small;

    @include mq($until: tablet) {
        width: 80px;
    }

    a {
        text-decoration: none;
    }

    span.is-active {
        color: var(--base-color);
        font-weight: bold;
    }
}

.c-header-top__right {
    display: flex;
    align-items: center;
}

.c-header-top__partners {
    @include mq($until: mobile) {
        display: none;
    }

    display: flex;
    align-items: center;
    margin-right: $spacing-unit;
    color: var(--grey-dark-color);
    font-weight: 600;

    span {
        @include mq($until: tablet) {
            display: none;
        }
    }

    a {
        margin-left: $spacing-unit-tiny;
    }
}