.c-features {
    + .c-box {
        margin-top: $spacing-unit;

        @include mq($from: desktop) {
            margin-top: 48px;
        }
    }
}

// List

.c-features-nav {
    @include mq($from: desktop) {
        padding-top: $spacing-unit-huge;
        padding-right: $spacing-unit-huge;
        padding-bottom: $spacing-unit-huge;
    }
}

.c-features-nav__item {
    + .c-features-nav__item {
        margin-top: $spacing-unit;
    }
}

// Suffix

.c-features-suffix {
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grey-dark-color);
    margin-top: $spacing-unit;

    @include mq($from: desktop) {
        margin-top: 48px;
        max-width: 720px;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

// Media

.c-features-carousel {
    background-color: var(--white-color);
    border-radius: 16px 4px 32px 4px;
    overflow: hidden;

    // Fix - Mac Safari border-radius transform issue
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    @include mq($until: desktop) {
        display: none;
    }
}

.c-features-carousel__cell {
    width: 100%;

    > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

// Teaser

.c-feature-teaser {
    display: flex;
}

.c-feature-teaser__icon {
    width: 32px;
    flex-shrink: 0;
    margin-right: $spacing-unit-small;

    > img {
        width: 32px;
        height: auto;
    }
}

.c-feature-teaser__main {
    flex: 1;
    min-width: 0;
}

.c-feature-teaser__title {
    appearance: none;
    background: transparent;
    border: 0;
    font-size: 2rem;
    line-height: 28px;
    font-weight: 700;
    padding: 0;
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    @include mq($from: desktop) {
        font-size: 2.4rem;
      }

    > svg {
        margin-top: 5px;
        margin-left: $spacing-unit-small;
    }

    &.is-selected {
        > svg {
            transform-origin: center center;
            transform: rotate(-90deg);
        }

        + .c-feature-teaser__content {
            display: block;
        }
    }
}

.c-feature-teaser__badge {
    font-size: 1.4rem;
    line-height: 24px;
    background-color: var(--feature-teaser-badge-bg);
    color: var(--feature-teaser-badge-color);
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 4px;
    padding-right: $spacing-unit-small;
    padding-left: $spacing-unit-small;
    border-radius: 2px 4px 2px 8px;
    margin-right: $spacing-unit-tiny;
}

.c-feature-teaser__title-text {
    display: inline-block;
    background: linear-gradient(to bottom, transparent 50%, var(--brand-light-color) 50%);
}

.c-feature-teaser__content {
    display: none;
    padding-top: $spacing-unit-small;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}