.l-top {
    font-size: 1.6rem;
    line-height: 1.5;
    border-bottom: 1px solid var(--grey-lightest-color);
    color: var(--grey-color);
}

.l-top__bar {
    padding: 12px $spacing-unit;
    background-color: var(--white-color);
    position: relative;
    z-index: 10;

    @include mq($from: tablet) {
        display: flex;
        justify-content: space-between;
    }
}