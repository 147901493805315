.c-nav-wrapper {
    min-height: 72px;
}

.c-nav {
    background-color: var(--white-color);
    padding: $spacing-unit-small $spacing-unit;

    &:not(.is-sticky) {
        box-shadow:
            0 0 0 1px var(--brand-light-color),
            1px 1px 32px rgba(0, 0, 0, 0.05);

        @include mq($from: tablet) {
            border-radius: 4px;
        }
    }

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.05);

        .c-nav__inner {
            @include mq($from: tablet) {
                max-width: 1280px;
                padding-right: $spacing-unit;
                padding-left: $spacing-unit;
                border-right: 1px solid var(--brand-light-color);
                border-left: 1px solid var(--brand-light-color);
            }
        }
    }
}

.c-nav__inner {
    display: flex;
    justify-content: space-between;
    max-width: 1232px;
    margin-right: auto;
    margin-left: auto;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
}

.c-nav__menu {
    @include mq($until: desktop) {
        display: none;

        @at-root .is-menu-open & {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            background-color: var(--white-color);
            padding: $spacing-unit;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }
}

.c-nav__close {
    @include mq($until: desktop) {
        display: block;
        position: absolute;
        top: $spacing-unit;
        right: $spacing-unit;
        width: 40px;
        height: 40px;
        appearance: none;
        padding: 0;
        border: 0;
        background: transparent;
    }

    @include mq($from: desktop) {
        display: none;
    }
}

.c-nav__menu-logo {
    @include mq($until: desktop) {
        display: block;
        width: 40px;
        height: 40px;
        margin-bottom: $spacing-unit-large;
    }

    @include mq($from: desktop) {
        display: none;
    }
}

.c-nav-list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include mq($from: desktop) {
        display: flex;
        flex-wrap: wrap;

        .c-nav-list__item + .c-nav-list__item {
            margin-left: $spacing-unit-huge;
        }
    }
}

.c-nav-list__item {
    margin: 0;
    padding: 0;

    + .c-nav-list__item {
        @include mq($until: desktop) {
            margin-top: $spacing-unit-small;
        }

        @include mq($from: desktop) {
            margin-left: $spacing-unit-small;
        }
    }
}

.c-nav-button-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.c-nav-button-list__item {
    margin: 0;
    padding: 0;

    + .c-nav-button-list__item {
        margin-left: $spacing-unit-small;
    }
}

.c-nav-list__link {
    font-weight: 600;
    text-decoration: none;
    color: var(--grey-dark-color);

    @include mq($until: desktop) {
        font-size: 2.6rem;
        line-height: 1.2;
    }

    @include mq($from: desktop) {
        display: block;
        font-size: 1.8rem;
        line-height: 24px;
        padding-top: $spacing-unit-tiny;
        padding-bottom: $spacing-unit-tiny;
    }

    &:hover,
    &:focus {
        outline: 0;
        color: var(--base-color);
    }

    &.is-active {
        color: var(--base-color);

        @include mq($from: desktop) {
            position: relative;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background: var(--brand-color);
                position: absolute;
                left: 0;
                bottom: 0;
                transform: translateY(16px);
            }
        }
    }
}

.c-nav-button {
    font-size: 1.6rem;
    line-height: 20px;
    display: inline-block;
    font-weight: 600;
    appearance: none;
    box-shadow: 0 1px 3px rgba(32, 30, 29, 0.14), 0 4px 6px rgba(32, 30, 29, 0.1);
    border: 0;
    border-radius: 4px;
    background-color: var(--link-color);
    text-decoration: none;
    padding: 10px $spacing-unit-small;
    color: var(--white-color);

    &:hover {
        background-color: var(--link-dark-color);
    }

    &:focus {
        outline: 0;
        background-color: var(--link-dark-color);
        box-shadow:
            0 0 0 2px var(--link-lightest-color),
            0 1px 3px rgba(32, 30, 29, 0.14),
            0 4px 6px rgba(32, 30, 29, 0.1);
    }
}

.c-nav-search {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    appearance: none;
    padding: 0;
    border: 0;
    border-radius: 4px;
    background: var(--grey-lightest-color);
    box-shadow: 0 1px 3px rgba(32, 30, 29, 0.14), 0 4px 6px rgba(32, 30, 29, 0.1);

    &:hover {
        background-color: var(--grey-light-color);
    }

    &:focus {
        outline: 0;
        background-color: var(--grey-light-color);
        box-shadow:
            0 0 0 2px var(--grey-lightest-color),
            0 1px 3px rgba(32, 30, 29, 0.14),
            0 4px 6px rgba(32, 30, 29, 0.1);
    }
}

.c-nav-toggle {
    font-size: 1.8rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    font-weight: 600;
    appearance: none;
    border: 0;
    border-radius: 0;
    background: transparent;
    text-decoration: none;
    padding: 0;
    color: var(--base-color);

    @include mq($from: desktop) {
        display: none;
    }
}

.c-nav-toggle__icon {
    margin-right: $spacing-unit-tiny;
}