.c-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 7000;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @at-root .has-scroll & {
        overflow-y: scroll;
    }

    &:focus {
        outline: 0;
    }
}

.c-popup__overlay {
    width: 100%;
    width: calc(100% - var(--scrollbarWidth, 15px));
    height: 100%;
    background: var(--grey-darkest-color);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.5;
    transition: opacity 0.2s ease;
    z-index: 1900;
    pointer-events: none;
}

.c-popup__inner {
    min-height: 100%;
    display: flex;
    padding: 48px $spacing-unit;
}

.c-popup-modal {
    position: relative;
    z-index: 2000;
    background-color: var(--white-color);
    padding: $spacing-unit-huge $spacing-unit;
    margin: auto;
    color: var(--base-color);
    max-width: 620px;
    width: 100%;
    border-radius: 16px 4px 32px 4px;
}

.c-popup-close {
    appearance: none;
    position: absolute;
    top: $spacing-unit-small;
    right: $spacing-unit-small;
    width: 32px;
    height: 32px;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
}