.c-side-block {
    position: relative;

    + .c-side-block {
        margin-top: $spacing-unit;
        
        @include mq($from: desktop) {
            margin-top: $spacing-unit-huge;
        }
    }
}

.c-side-block--brand {
    .c-side-block__inner {
        background-color: var(--side-block-brand-inner-bg);
        border-color: var(--side-block-brand-inner-border);
    }

    .c-side-block__side {
        background-color: var(--side-block-brand-side-bg);
        color: var(--side-block-brand-side-color);
    }

    .c-side-block__title {
        color: var(--side-block-brand-title-color);
    }

    .c-side-block__subtitle {
        color: var(--side-block-brand-subtitle-color);
    }
}

.c-side-block--secondary {
    .c-side-block__inner {
        background-color: var(--white-color);
        border-color: var(--grey-light-color);
    }

    .c-side-block__side {
        background-color: var(--grey-light-color);
    }
}

.c-side-block__gfx {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(24px, -24px);

    > path:first-child {
        fill: var(--brand-color);
    }
}

.c-side-block__inner {
    position: relative;
    border: 2px solid var(--grey-color);
    background-color: var(--white-color);
    border-radius: 16px 4px 32px 4px;
    overflow: hidden;
    box-shadow: $default-shadow;
}

.c-side-block__main {
    z-index: 10;
    padding: $spacing-unit;
}

.c-side-block__side {
    background-color: var(--grey-color);
    padding: $spacing-unit;
    text-align: center;
}

.c-side-block__title {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: $spacing-unit-small;
    color: var(--base-color);
}

.c-side-block__intro {
    color: var(--grey-dark-color);
    margin-bottom: $spacing-unit;
}

.c-side-block__subtitle {
    color: var(--base-color);
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: $spacing-unit-small;
}

.c-side-block__body {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-side-block__action {
    margin-top: $spacing-unit-small;
    text-align: center;
}

.c-side-block__cover {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-unit-small;

    .c-side-block__title {
        margin-left: $spacing-unit-small;
    }
}