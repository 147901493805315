.c-content-teaser {
    position: relative;
    padding-left: $spacing-unit-huge;

    &::before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background: var(--brand-color) url('/img/global/connect-white-icon.svg') no-repeat center center;
        position: absolute;
        top: 2px;
        left: 0;
    }

    + .c-content-teaser {
        margin-top: $spacing-unit-small;
    }
}

.c-content-teaser-title {
    font-size: 1.8rem;
    line-height: 1.44;
    font-weight: 600;
    color: var(--brand-darkest-color);
    margin-bottom: 0;
}

.c-content-teaser-title__link {
    display: inline-block;
    padding-right: 28px;
    text-decoration-color: var(--grey-lightest-color);

    &::after {
        content: '';
        padding-right: 12px;
        padding-left: 12px;
        background: url('/img/global/arrow-right-light-icon.svg') no-repeat center center;
        margin-left: 4px;
    }

    &[target="_blank"] {
        &::after {
            background-image: url('/img/global/external-light-icon.svg');
        }
    }

    &:hover {
        text-decoration-color: var(--grey-light-color);
    }
}

.c-content-teaser__meta {
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grey-color);
    margin-top: 2px;
}