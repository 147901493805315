.c-umb-grid {
    > .o-grid {
        + .o-grid {
            margin-top: $spacing-unit-huge;
        }
    }
}

.c-umb-grid-cell__item {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    + .c-umb-grid-cell__item {
        margin-top: $spacing-unit;
    }
}