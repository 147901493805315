.c-leadin {
    max-width: 768px;
}

.c-leadin--right {
    text-align: right;
    margin-left: auto;

}

.c-leadin--center {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.c-leadin--spaced-bottom {
    padding-bottom: 40px;

    @include mq($from: desktop) {
        padding-bottom: 80px;
    }
}

.c-leadin__title {
    font-size: 3rem;
    line-height: 1.25;
    color: var(--base-color);
    margin-bottom: 0;
    font-weight: 600;

    @include mq($from: desktop) {
        font-size: 4rem;
    }
}

.c-leadin__text {
    margin-top: $spacing-unit-large;
    font-size: 2rem;
    line-height: 1.3;

    @include mq($from: desktop) {
        font-size: 2.4rem;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    b,
    strong {
        color: var(--base-color);
    }
}