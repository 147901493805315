.c-editors {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: $spacing-unit;
    row-gap: $spacing-unit-tiny;

    @include mq($from: mobile) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: tablet) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.c-editors__actions {
    text-align: center;
    margin-top: $spacing-unit-huge;

    @include mq($from: desktop) {
        margin-top: 64px;
    }
}

.c-editor {
    text-align: center;
}

.c-editor__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    > svg {
        margin-left: 4px;
    }

    &:hover {
        .c-editor__link-label {
            text-decoration: underline;
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
        }
    }
}