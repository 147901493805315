.c-grid-rte {
    @include inuit-clearfix();

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    h2,
    h3,
    h4 {
        color: var(--base-color);
    }
}