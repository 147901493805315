.p-error-page {
    background-color: var(--light-bg-color);
}

.p-error-page__main {
    background-color: var(--white-color);
}

.p-error-page__header {
    padding-top: $spacing-unit-large;
}

.p-error-page__content {
    max-width: 700px;
    margin-top: 48px;
    margin-right: auto;
    margin-left: auto;
}

.p-error-page__footer {
    text-align: center;
    margin-top: 112px;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;

    img {
        max-width: 100%;
        height: auto;
        margin-bottom: -152px;
        padding-bottom: 40px;
    }
}

.p-error-page__logo {
    display: inline-block;

    > img {
        @include mq($from: desktop) {
            width: 420px;
            height: 106px;
        }
    }
}

.c-error-back {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    text-decoration: underline;

    > svg {
        margin-right: $spacing-unit-tiny;
    }
}

.c-error-back__title {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 600;
    color: var(--base-color);
    margin-top: $spacing-unit;
    margin-bottom: $spacing-unit;

    @include mq($from: desktop) {
        font-size: 3.2rem;
    }
}