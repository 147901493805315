.c-box {
    background-color: var(--brand-light-color);
    padding: $spacing-unit-large;
    border-radius: 16px 4px 32px 4px;
    position: relative;

    @include mq($from: desktop) {
        padding: 48px;
    }
}

.c-box__title {
    font-size: 2.6rem;
    line-height: 1.3;
    font-weight: 600;
}

.c-box__content {
    position: relative;
    z-index: 10;

    @include mq($from: desktop) {
        max-width: 1024px;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}