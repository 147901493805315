.c-search {
    max-width: 820px;
    margin-right: auto;
    margin-left: auto;
}

.c-search-box {
    display: flex;
}

.c-search-box__input {
    position: relative;
    flex: 1;
    min-width: 0;
}

.c-search-box__input-text {
    display: block;
    appearance: none;
    width: 100%;
    border: 2px solid var(--grey-lightest-color);
    font-size: 1.6rem;
    line-height: 1.5;
    border-radius: 4px;
    padding: 6px $spacing-unit-tiny;
    min-height: 40px;

    &:focus {
        outline: 0;
        border-color: var(--grey-light-color);
    }

    &:disabled,
    &[aria-disabled=true] {
        opacity: 0.5;
    }
}

.c-search-box__clear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background: transparent;
    position: absolute;
    top: 50%;
    right: $spacing-unit-tiny;
    transform: translateY(-50%);
}

.c-search-box__button {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    appearance: none;
    margin-left: $spacing-unit-small;
    padding: 0;
    border: 0;
    border-radius: 4px;
    background: var(--grey-lightest-color);
    box-shadow: 0 1px 3px rgba(32, 30, 29, 0.14), 0 4px 6px rgba(32, 30, 29, 0.1);

    &:hover {
        background-color: var(--grey-light-color);
    }

    &:focus {
        outline: 0;
        background-color: var(--grey-light-color);
        box-shadow:
            0 0 0 2px var(--grey-lightest-color),
            0 1px 3px rgba(32, 30, 29, 0.14),
            0 4px 6px rgba(32, 30, 29, 0.1);
    }
}

.c-search-results {
    margin-top: $spacing-unit-huge;
}

.c-search-results__item {
    + .c-search-results__item {
        margin-top: $spacing-unit;
        padding-top: $spacing-unit;
        border-top: 2px solid var(--light-bg-color);
    }
}

.c-search-results__title {
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: 0;
    
    a {
        color: var(--base-color);
        text-decoration-color: var(--grey-lightest-color);

        &:hover {
            text-decoration-color: var(--grey-light-color);
        }
    }
}

.c-search-results__snippet {
    margin-top: $spacing-unit-tiny;
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grey-color);

    b,
    strong {
        color: var(--grey-dark-color);
        font-weight: 600;
    }
}

.c-search__empty {
    margin-top: $spacing-unit-large;
}