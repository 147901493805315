.c-related-content {
    background-color: var(--white-color);
    padding: $spacing-unit;
    border-radius: 16px 4px 4px 4px;
}

.c-related-item__title {
    font-size: 1.8rem;
    line-height: 1.44;
    margin-bottom: 0;
    
    a {
        color: var(--base-color);
        text-decoration-color: var(--grey-lightest-color);
        margin-right: 4px;

        &:hover {
            text-decoration-color: var(--grey-light-color);
        }
    }
}

.c-related-item {
    display: flex;

    + .c-related-item {
        border-top: 2px solid var(--light-bg-color);
        margin-top: $spacing-unit-small;
        padding-top: $spacing-unit-small;
    }
}

.c-related-item__image {
    flex-shrink: 0;
    margin-right: $spacing-unit-small;
}

.c-related-item__main {
    flex: 1;
    min-width: 0;
    padding-top: 4px;
}

// Teaser

.c-related-teaser {
    background-color: var(--related-teaser-bg);
    color: var(--related-teaser-color);
    padding: $spacing-unit;
    border-radius: 4px 4px 32px 4px;
    text-align: center;
}

.c-related-teaser__title {
    color: var(--related-teaser-title-color);
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: $spacing-unit-small;
}

.c-related-content__body {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-related-teaser__action {
    margin-top: $spacing-unit-small;
}