.c-pager {
    display: flex;
    justify-content: center;
    border-top: 2px solid var(--light-bg-color);
    margin-top: $spacing-unit;
    padding-top: $spacing-unit;
}

.c-pager__text-button,
.c-pager__link {
    display: block;
    appearance: none;
    padding-right: $spacing-unit-tiny;
    padding-left: $spacing-unit-tiny;
    border: 0;
    background: transparent;
    color: var(--link-color);
    font-weight: 600;
    border-radius: 4px;
    text-decoration: none;

    &[disabled],
    &.is-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:hover {
        background-color: var(--grey-lightest-color);
    }
}

.c-pager__text-button {
    &:first-child {
        margin-right: $spacing-unit-small;
    }

    &:last-child {
        margin-left: $spacing-unit-small;
    }
}

.c-pager__link {
    @include mq($until: desktop) {
        display: none;
    }

    &.is-current {
        color: var(--grey-color);
    }
}

.c-pager__ellipsis {
    margin-right: $spacing-unit-tiny;
    margin-left: $spacing-unit-tiny;

    @include mq($until: desktop) {
        display: none;
    }
}