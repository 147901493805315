.c-block {
    + .c-block {
        margin-top: $spacing-unit-huge;
    }
}

.c-block--small {
    max-width: 820px;
    margin-right: auto;
    margin-left: auto;
}

.c-block__title {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 600;
    color: var(--base-color);
    margin-bottom: $spacing-unit;

    @include mq($from: desktop) {
        font-size: 3.2rem;
        margin-bottom: $spacing-unit-large;
    }
}

.c-block__intro {
    font-size: 2.2rem;
    margin-bottom: $spacing-unit-large;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-block__title--centered,
.c-block__intro--centered {
    max-width: 640px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}