.c-portals {
    background-color: var(--white-color);
    border-radius: 4px;
    width: calc(100vw - 24px - var(--scrollbarWidth, 15px));
    min-width: 351px;
    box-shadow: 0 0 8px rgb(0 0 0 / 10%);
    overflow: hidden;
    padding: $spacing-unit;

    @include mq($from: desktop) {
        padding: $spacing-unit-huge;
        width: calc(100vw - 48px - var(--scrollbarWidth, 15px));
    }
}

.c-portals__inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: $spacing-unit;
    grid-row-gap: $spacing-unit;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
}