.c-products {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: $spacing-unit;
    row-gap: $spacing-unit;

    @include mq($from: tablet) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.c-product {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--white-color);
    border-radius: 4px;
    padding: $spacing-unit $spacing-unit-large $spacing-unit $spacing-unit;
    border: 1px solid var(--grey-lightest-color);
    box-shadow: $default-shadow;
    overflow: hidden;

    @include mq($from: desktop) {
        padding: $spacing-unit-large 72px $spacing-unit-large $spacing-unit-large;
    }
}

.c-product__emblem {
    @include mq($until: desktop) {
        display: none;
    }

    @include mq($from: desktop) {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        max-width: 60px;
    }
}

.c-product__image {
    display: block;
    margin-bottom: $spacing-unit;
}

.c-product__main {
    position: relative;
    flex: 1;
    min-width: 0;

    @include mq($from: desktop) {
        padding-right: 112px;
    }
}