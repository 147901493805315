.c-carousel {
    @include mq($until: desktop) {
        padding-bottom: 64px;
        border-bottom: 2px solid var(--grey-lightest-color);
    }

    .flickity-viewport {
        transition: height 0.2s ease;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
    }

    .flickity-prev-next-button {
        background: var(--highlights-prev-next-button-bg);
        color: var(--highlights-prev-next-button-color);
        width: 40px;
        height: 40px;
        box-shadow: 0 1px 3px rgba(32, 30, 29, 0.14), 0 4px 6px rgba(32, 30, 29, 0.1);
        padding: 0;
        border-radius: 100%;
        overflow: hidden;

        @include mq($until: desktop) {
            display: none;
        }

        @include mq($from: desktop) {
            &.previous {
                left: 0;
                transform: translate(-50%, -50%);
            }

            &.next {
                right: 0;
                transform: translate(50%, -50%);
            }
        }

        @include mq($from: wide) {
            width: 48px;
            height: 48px;
        }
    }

    .flickity-button {
        &:disabled {
            display: none;
        }
    }
    
    .flickity-button-icon {
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
    }

    .flickity-page-dots {
        display: flex;
        justify-content: center;
        bottom: $spacing-unit;

        @include mq($from: desktop) {
            bottom: 0;
            transform: translateY(calc(100% + #{$spacing-unit}));
        }

        .dot {
            width: 16px;
            height: 16px;
            background: transparent;
            opacity: 1;
            margin-right: 4px;
            margin-left: 4px;
            position: relative;
            border: 1px solid transparent;

            &::before {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background-color: var(--grey-light-color);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &.is-selected {
                border-color: var(--brand-color);

                &::before {
                    background-color: var(--brand-color);
                }
            }
        }   
    }
}

.c-carousel__cell {
    width: 100%;

    @include mq($from: desktop) {
        overflow: hidden;
    }
}