.c-top-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.c-top-nav__item {
    margin: 0;
    padding: 0;

    + .c-top-nav__item {
        margin-left: $spacing-unit;
    }
}

.c-top-nav__link {
    color: inherit;
    text-decoration-color: var(--grey-lightest-color);

    &:hover {
        text-decoration-color: var(--grey-light-color);
    }

    &[target="_blank"] {
        display: inline-flex;
        align-items: center;

        &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url('/img/global/external-light-icon.svg') no-repeat center center;
            margin-left: $spacing-unit-tiny;
        }
    }
}