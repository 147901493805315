.c-logos {
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: $default-shadow;
    padding: $spacing-unit;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.c-logo {
    width: 100%;
    height: 100px;
    padding: $spacing-unit-small;
    text-align: center;

    @include mq($from: mobile, $until: desktop) {
        width: 50%;
    }

    @include mq($from: desktop) {
        width: 25%;
    }

    img {
        width: 100%;
        max-width: 280px;
        height: 100%;
        object-fit: contain;
    }
}