.c-cta {
    background-color: var(--light-bg-color);
    border-radius: 4px 32px 4px 16px;
    margin-top: 56px;
    padding: 32px 56px 32px 48px;
}

.c-cta-grid {
    @include mq($from: tablet) {
        display: flex;
        align-items: flex-start;
    }
}

.c-cta-grid__main {
    @include mq($from: tablet) {
        flex: 1;
        min-width: 0;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-cta-grid__action {
    @include mq($until: tablet) {
        margin-top: $spacing-unit-small;
    }

    @include mq($from: tablet) {
        flex-shrink: 0;
        margin-left: 64px;
    }

    @include mq($from: wide) {
        margin-left: 128px;
    }
}

.c-cta__title {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: $spacing-unit-small;
    color: var(--base-color);
    position: relative;

    @include mq($from: desktop) {
        font-size: 2.4rem;
      }

    &::before {
        content: '';
        display: block;
        width: 56px;
        height: 9px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(calc(-100% - 16px), -50%);
        background: url('/img/global/gfx-underline-small.svg') no-repeat;
    }
}