// Default colors - overwrite via theme file.

:root {
    --brand-darkest-color: #53575c;
    --brand-dark-color: #74757b;
    --brand-color: #a0a2a5;
    --brand-light-color: #c6c7c9;
    --brand-lightest-color: #ececed;

    //
    // Button
    //

    // Brand
    --button-brand-bg-color: var(--brand-color);
    --button-brand-text-color: var(--brand-darkest-color);
    --button-brand-external-icon: url('/img/global/external-dark-icon.svg');

    // Dark
    --button-dark-bg-color: var(--brand-darkest-color);
    --button-dark-text-color: var(--brand-lightest-color);
    --button-dark-external-icon: url('/img/global/external-light-icon.svg');

    // Light
    --button-light-bg-color: var(--brand-lightest-color);
    --button-light-text-color: var(--brand-darkest-color);
    --button-light-external-icon: url('/img/global/external-dark-icon.svg');

    //
    // Gfx
    //

    --gfx-divider: url('/img/global/gfx-divider.svg');
}