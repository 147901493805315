.o-grid--spaced {
    row-gap: $spacing-unit;
}

.o-grid--spaced-large {
    row-gap: $spacing-unit-large;
}

.o-grid--spaced-huge {
    row-gap: $spacing-unit-huge;
}

@include mq($until: desktop) {
    .o-grid--spaced\@portable {
        row-gap: $spacing-unit;
    }

    .o-grid--spaced-large\@portable {
        row-gap: $spacing-unit-large;
    }
    
    .o-grid--spaced-huge\@portable {
        row-gap: $spacing-unit-huge;
    }
}