.c-grid-logos {
    position: relative;
    padding-top: $spacing-unit-huge;
    padding-bottom: $spacing-unit-huge;

    @include mq($from: desktop) {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    
    &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        z-index: -1;
        background-color: var(--brand-lightest-color);
    }
}

.c-grid-logos__title {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 600;
    color: var(--base-color);
    margin-bottom: $spacing-unit;
    text-align: center;

    @include mq($from: desktop) {
        font-size: 3.2rem;
        max-width: 768px;
        margin-right: auto;
        margin-left: auto;
    }
}