.l-footer {
    background-color: var(--light-bg-color);
    padding-top: 48px;
    padding-bottom: $spacing-unit;
}

.l-footer__inner {
    max-width: 1328px;
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
}

// Components

.c-footer-logo {
    margin-bottom: $spacing-unit;
}

.c-footer-logo__image {
    @include mq($from: wide) {
        margin-left: -52px;
    }
}

.c-footer-title {
    font-size: 1.4rem;
    line-height: 1.25;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--grey-darkest-color);
    margin-bottom: $spacing-unit-small;
}

.c-footer-bottom {
    font-size: 1.6rem;
    line-height: 1.5;
    color: var(--grey-color);
    border-top: 1px solid var(--grey-lightest-color);
    padding-top: $spacing-unit;

    @include mq($until: desktop) {
        text-align: center;
    }

    @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
    }
}

.c-footer-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    @include mq($until: desktop) {
        justify-content: center;
        margin-bottom: $spacing-unit-tiny;
    }
}

.c-footer-nav__item {
    margin: 0;
    padding: 0;

    + .c-footer-nav__item {
        margin-left: $spacing-unit;
    }
}

.c-footer-nav__link {
    color: inherit;
    text-decoration-color: var(--grey-lightest-color);

    &[target="_blank"] {
        display: inline-flex;
        align-items: center;

        &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url('/img/global/external-light-icon.svg') no-repeat center center;
            margin-left: $spacing-unit-tiny;
        }
    }

    &:hover {
        text-decoration-color: var(--grey-light-color);
    }
}

.c-footer-links {
    margin: 0;
    padding: 0;
    list-style: none;
}

.c-footer-links__item {
    + .c-footer-links__item {
        margin-top: 4px;
    }
}

.c-footer-links__link {
    color: var(--grey-dark-color);
    text-decoration-color: var(--grey-lightest-color);

    &[target="_blank"] {
        display: inline-flex;
        align-items: center;

        &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url('/img/global/external-light-icon.svg') no-repeat center center;
            margin-left: $spacing-unit-tiny;
        }
    }

    &:hover {
        text-decoration-color: var(--grey-light-color);
    }
}

.c-powered-by {
    margin-top: $spacing-unit;
    margin-bottom: 48px;
}

.c-powered-by__label {
    font-size: 16px;
    line-height: 1.5;
    color: var(--grey-darkest-color);
    margin-bottom: $spacing-unit-tiny;
}

.c-powered-by__link {
    display: inline-block;
    text-decoration: none;
}