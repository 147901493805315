///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

$mq-breakpoints: (
  mobile: 480px,
  tablet: 768px,
  desktop: 1024px,
  wide: 1440px,
);

// Use in development
// $mq-show-breakpoints: ('mobile', 'tablet', 'desktop', 'wide');

$base-font-family: 'Source Sans Pro', sans-serif;

// Activate grid offsets
$inuit-offsets: false;

// Grid
$inuit-flexgrid-gutter-width: $spacing-unit;

$inuit-flexgrid-spacing-sizes: (
  null: $inuit-flexgrid-gutter-width,
  '--tiny': $spacing-unit-tiny,
  '--small': $spacing-unit-small,
  '--large': $spacing-unit-large,
  '--huge': $spacing-unit-huge,
  '--flush': 0,
);