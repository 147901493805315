.c-page-cta {
    position: relative;
    color: var(--brand-darkest-color);
    padding-top: 64px;
    padding-bottom: 64px;
    
    &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        z-index: -1;
        background-color: var(--brand-lightest-color);
    }
}

.c-page-cta__inner {
    max-width: 1328px;
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
}

.c-page-cta--brand {
    color: var(--list-cta-brand-color);

    &::before {
        background-color: var(--list-cta-brand-bg);
    }

    .c-page-cta-grid__action {
        > * {
            &:nth-child(1) {
                @extend %c-button--dark;
            }

            &:nth-child(2) {
                @extend %c-button--light;
            }
        }
    }
}

.c-page-cta-grid {
    @include mq($from: tablet) {
        display: flex;
        align-items: flex-start;
    }
}

.c-page-cta-grid__main {
    @include mq($from: tablet) {
        flex: 1;
        min-width: 0;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-page-cta-grid__action {
    display: flex;
    flex-wrap: wrap;

    @include mq($until: tablet) {
        margin-top: $spacing-unit-small;
    }

    @include mq($from: tablet) {
        flex-shrink: 0;
        margin-left: 64px;
    }

    @include mq($from: wide) {
        margin-left: 128px;
    }

    > * {
        @extend %c-button;

        &:nth-child(1) {
            @extend %c-button--brand;
        }

        &:nth-child(2) {
            @extend %c-button--dark;
        }

        + * {
            margin-left: $spacing-unit;
        }
    }
}

.c-page-cta__title {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: $spacing-unit-small;

    @include mq($from: desktop) {
        font-size: 2.4rem;
    }
}