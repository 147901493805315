.c-checkbox {
    display: flex;
    align-items: center;

    > input {
        margin-right: $spacing-unit-tiny;
    }
}

.c-checkbox--centered {
    justify-content: center;
}