.c-block-grid__row {
    &:not(:first-child) {
        margin-top: $spacing-unit-huge;

        @include mq($from: desktop) {
            margin-top: 80px;
        }
    }

    &:last-child {
        .c-grid-cta,
        .c-grid-logos {
            &:last-child {
                margin-bottom: -$spacing-unit-huge;

                @include mq($from: desktop) {
                    margin-bottom: -80px;
                }
            }
        }
    }

    > .o-grid {
        &.o-grid--6\/6,
        &.o-grid--5\/7,
        &.o-grid--7\/5 {
            @include mq($from: desktop) {
                .c-grid-image {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                    }
                }
            }

            > .o-grid__cell {
                @include mq($from: desktop) {
                    &:first-child {
                        .c-grid-image {
                            &::before {
                                transform: translate(-24px, -24px);
                                border-radius: 4px 4px 4px 24px;
                            }
    
                            img {
                                border-radius: 4px 4px 4px 24px;
                            }
                        }
                    }
    
                    &:last-child {
                        .c-grid-image {
                            &::before {
                                transform: translate(24px, 24px);
                                border-radius: 4px 4px 24px 4px;
                            }
    
                            img {
                                border-radius: 4px 4px 24px 4px;
                            }
                        }
                    }
                }
            }
        }

        > .o-grid__cell {
            > * + * {
                margin-top: $spacing-unit;
    
                @include mq($from: desktop) {
                    margin-top: $spacing-unit-huge;
                }
            }

            > .c-grid-logos + .c-grid-cta,
            > .c-grid-cta + .c-grid-logos {
                margin-top: 0;
            }
        }
    }
}

.c-block-grid__row--light,
.c-block-grid__row--brand,
.c-block-grid__row--dark,
.c-block-grid__row--grey {
    position: relative;
    padding-top: $spacing-unit-huge;
    padding-bottom: $spacing-unit-huge;

    @include mq($from: desktop) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &:last-child {
        margin-bottom: -$spacing-unit-huge;

        @include mq($from: desktop) {
            margin-bottom: -80px;
        }
    }
    
    &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        z-index: -1;
    }

    .c-grid-rte {
        h2,
        h3,
        h4 {
            color: currentColor;
        }
    }

    .c-section-title,
    .c-card__title {
        color: currentColor;
    }

    > .o-grid {
        > .o-grid__cell {
            > * + * {
                margin-top: $spacing-unit-huge;
    
                @include mq($from: desktop) {
                    margin-top: 80px;
                }
            }
        }
    }
}

.c-block-grid__row--light + .c-block-grid__row--light,
.c-block-grid__row--light + .c-block-grid__row--brand,
.c-block-grid__row--light + .c-block-grid__row--dark,
.c-block-grid__row--light + .c-block-grid__row--grey,
.c-block-grid__row--brand + .c-block-grid__row--brand,
.c-block-grid__row--brand + .c-block-grid__row--light,
.c-block-grid__row--brand + .c-block-grid__row--dark,
.c-block-grid__row--brand + .c-block-grid__row--grey,
.c-block-grid__row--dark + .c-block-grid__row--dark,
.c-block-grid__row--dark + .c-block-grid__row--brand,
.c-block-grid__row--dark + .c-block-grid__row--light,
.c-block-grid__row--dark + .c-block-grid__row--grey,
.c-block-grid__row--grey + .c-block-grid__row--grey,
.c-block-grid__row--grey + .c-block-grid__row--brand,
.c-block-grid__row--grey + .c-block-grid__row--light,
.c-block-grid__row--grey + .c-block-grid__row--dark {
    margin-top: 0;
}

.c-block-grid__row--light {
    color: var(--block-grid-light-color);

    &::before {
        background-color: var(--block-grid-light-bg);
    }
}

.c-block-grid__row--brand {
    color: var(--block-grid-brand-color);

    &::before {
        background-color: var(--block-grid-brand-bg);
    }
}

.c-block-grid__row--dark {
    color: var(--block-grid-dark-color);

    &::before {
        background-color: var(--block-grid-dark-bg);
    }
}

.c-block-grid__row--grey {
    color: var(--block-grid-grey-color);

    &::before {
        background-color: var(--block-grid-grey-bg);
    }
}

//
// Components
//

// Grid image

.c-grid-image {
    &::before {
        background-color: var(--brand-lightest-color);

        @at-root .c-block-grid__row--light & {
            background-color: var(--brand-light-color);
        }
    }

    > img {
        border-radius: 4px;
    }
}

// Highlighted article

.c-grid-highlighted-article {
    background-color: var(--light-bg-color);
    padding: $spacing-unit;
    border-radius: 4px;

    @include mq($from: desktop) {
        padding: $spacing-unit-huge;
    }
}

.c-grid-highlighted-article-grid {
    @include mq($from: desktop) {
        display: flex;
        align-items: center;
    }
}

.c-grid-highlighted-article-grid__text {
    padding-left: 48px;

    @include mq($from: desktop) {
        flex: 1;
        min-width: 0;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.c-grid-highlighted-article-grid__side {
    @include mq($until: desktop) {
        margin-top: $spacing-unit;
        padding-left: 48px;
    }

    @include mq($from: desktop) {
        flex-shrink: 0;
        margin-left: 64px;
    }
}

.c-grid-highlighted-article__header {
    margin-bottom: $spacing-unit;
}

.c-grid-highlighted-article__title {
    font-size: 2.8rem;
    line-height: 1.2;
    font-weight: 600;
    color: var(--base-color);
    margin-bottom: 0;
    position: relative;
    padding-left: 48px;

    @include mq($from: desktop) {
        font-size: 3.2rem;
    }
}

.c-grid-highlighted-article__suffix {
    padding-top: 4px;
    padding-left: 48px;
}

.c-grid-highlighted-article__icon {
    display: block;
    width: 32px;
    position: absolute;
    top: 2px;
    left: 0;
}

// Cta button grid

.c-cta-button-grid {
    text-align: left;
}

.c-cta-button-grid--center {
    text-align: center;
}

.c-cta-button-grid--right {
    text-align: right;
}